<template>
    <main class="l-page">
        <div class="p-about l-container">
            <h1 class="p-about__ttl--primary c-ttl--primary">集英社ID 利用規約</h1>
            <section class="p-about__sec">
                <p style="margin-bottom: 1rem">「集英社ID」は、株式会社集英社（以下、「当社」といいます。）が運営するサービスをより便利に活用していただくためのアカウントIDです。集英社IDを利用いただくことで、当社が提供する個々のサービス（以下、「個別サービス」といいます。）の利用がスムーズに行えるようになります。集英社IDの利用により受けることができる当社のサービスを総称して「本サービス」といいます。対象となる個別サービスは<a class="c-link--primary" href="#services">こちら</a>（最下部のサービス一覧）をご確認ください。</p>
                <p style="margin-bottom: 1rem">集英社IDの利用を希望される方は、メールアドレス、パスワードをご登録ください。</p>
                <p style="margin-bottom: 1rem">集英社IDの利用にあたっては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。なお、個別サービスには、追加のご登録手続が必要となるものがあります。この場合には、個別サービスにおいて別途掲示される利用規約等（以下、「個別規約」といいます。）をご確認いただき、追加のご登録手続をお願いいたします。なお、本規約の内容と個別規約の内容が異なる場合は、個別規約の内容が優先して適用されるものとします。</p>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第１条（登録手続きと契約の成立）</h2>
                <ul class="p-about__listnum p-about__listnum--01">
                    <li>集英社IDの利用を希望する方は、本規約に同意のうえ所定の登録手続きを行うものとし、当社がこれを承諾してその登録が完了した時に、登録をした方（以下、「利用者」といいます。)と当社との間で、本規約を内容とする契約が成立するものとします。</li>
                    <li>当社の判断で集英社IDの登録をお断りする場合がありますが、その理由等については一切開示しないものとします。集英社IDの登録ができないことにより、利用を希望する方に損害が生じたとしても、当社はその責任を負わないものとします。</li>
                    <li>登録手続きは真実かつ正確な情報をもってこれを行うものとし、登録すべき情報に虚偽、誤り、または記入漏れがあったことにより、登録手続きを行った方に損害が生じたとしても、当社はその責任を負わないものとします。</li>
                    <li>登録手続きは、登録をしようとする本人が行うものとし、18歳未満の方は、保護者の事前の同意を得たうえで登録手続きを行うものとします。</li>
                </ul>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第２条（個人情報および個人に関する情報の取り扱い）</h2>
                <ul class="p-about__listnum p-about__listnum--01">
                    <li>
                        当社は、利用者が登録した個人情報を以下の利用目的の範囲内で利用するものとし、利用者は、これに同意するものとします。利用者本人から同意を得たとき、法令等の定めにより開示を要求された場合を除き、当社は取得した個人情報を第三者に提供いたしません。
                        <ul class="p-about__listnum p-about__listnum--02">
                            <li>懸賞・キャンペーン情報の提供、プレゼントの発送</li>
                            <li>商品の配送その他当社の各種サービスの提供に必要な連絡</li>
                            <li>当社の各種サービスに関連する通知、連絡、提案、問合せ、情報提供</li>
                            <li>当社の各種サービスの改善や当社の新サービスの開発の検討のための意見募集</li>
                            <li>当社および当社グループ会社各社の書籍・雑誌・イベント・物販、その他の商品・サービスの案内（メール配信、郵送を含む。）</li>
                            <li>当社、当社グループ会社および当社が認めた会社・団体の商品・サービスに関するアンケートの送付・回収</li>
                        </ul>
                    </li>
                    <li>前項のほか、当社は本サービスの利用に関して取得する利用者の個人情報およびアクセスログ情報等の「個人関連情報」の取り扱いについては、「集英社プライバシーガイドライン」に従い適切に取り扱います。</li>
                    <li>利用者が集英社IDの利用を終了した場合、または当社が利用者による集英社IDの利用を永続的に停止した場合には、当社は個人情報を含む当該利用者の登録情報を消去します。ただし、当社は、履歴検証を行う場合その他当社が必要と認めた場合には、当該情報のバックアップデータを一定期間保有し、その後消去することができます。</li>
                </ul>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第３条（集英社IDの管理）</h2>
                <ul class="p-about__listnum p-about__listnum--01">
                    <li>利用者は、集英社IDの識別情報（ID）としてメールアドレスを使用していること及びパスワードが他人に知られることのないよう注意し、責任をもって集英社IDを管理および使用するものとします。</li>
                    <li>当社は、入力された集英社IDのメールアドレスとパスワードの組合せが利用者の登録したものと一致することを確認した場合、当該利用者による利用があったものとみなします。</li>
                    <li>利用者は、集英社IDを他人に貸与して使用させること、他人の集英社IDを借り受けて使用すること、他人を代理人にして集英社IDを使用させること、または、集英社IDの譲渡、売買等をしてはならないものとします。</li>
                    <li>集英社IDの管理不十分、使用上の過誤、ならびに第三者の使用等によって生じた損害については、利用者が責任を負うものとし、当社はその責任を負わないものとします。</li>
                    <li>当社は、同一の集英社IDを利用して複数の端末から同時に行われた接続、または複数の集英社IDを利用して同じ端末から同時に行われた接続等による利用の結果については一切保証しないものとします。</li>
                    <li>利用者が作成および保有できる集英社IDは利用者一人につき1つとし、利用者は複数の集英社IDを作成または保有してはならないものとします。また、利用者が複数の集英社IDの作成をしたとしても、それらを１つに統合することはできないものとします。</li>
                </ul>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第４条（登録情報の変更）</h2>
                <p class="p-about__txt">登録情報に変更が生じた場合、利用者は、速やかに当社所定の変更手続を行うものとします。変更手続きを怠ったことで利用者に損害が生じたとしても、当社はその責任を負わないものとします。</p>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第５条（集英社IDの利用終了）</h2>
                <ul class="p-about__listnum p-about__listnum--01">
                    <li>利用者は、当社所定の手続きを行い、それを当社が確認したことにより、集英社IDの利用を終了することができます。</li>
                    <li>集英社IDの利用終了により、利用者は、集英社IDを使ってログインをする個別サービスの利用ができなくなる場合があることをあらかじめ承諾するものとします。</li>
                    <li>集英社IDの利用終了により、終了時点において利用者が集英社IDに関連して有していた権利は全て消滅し、利用者は、当社に対して何らの請求も行えないものとします。なお、利用終了以前において利用者が当社に対して、集英社IDまたは個別サービスに関して債務を負っていた場合、当該債務は消滅せず、利用者は、当社に対して、利用終了後ただちに全ての当該債務について弁済しなければならないものとします。</li>
                </ul>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第６条（禁止事項）</h2>
                <p class="p-about__txt">本サービスの利用に関して、利用者は、以下の各号に定める行為、またはそのおそれのある行為を行わないものとします。</p>
                <ul class="p-about__listnum p-about__listnum--01">
                    <li>当社または第三者の権利を侵害する行為</li>
                    <li>他者を誹謗中傷もしくは侮辱する行為、または他者の名誉もしくは信用を毀損する行為</li>
                    <li>第三者の個人情報その他のプライバシーに関する情報を不正に収集、開示、または提供する行為</li>
                    <li>第三者になりすます行為、または意図的に虚偽の情報を送信する行為</li>
                    <li>宗教団体その他の団体・組織への加入を勧誘する行為</li>
                    <li>出資、寄付、資金提供等を勧誘する行為</li>
                    <li>不正アクセス行為、第三者の集英社ID利用する行為</li>
                    <li>本サービスの利用、運営等に支障をきたす行為、または本サービスの提供のための設備等を毀損する行為</li>
                    <li>本サービスの不具合や障害を不正な目的で利用し、またはそれを他者へ伝達する行為</li>
                    <li>当社のサーバーに過度の負担をかける行為</li>
                    <li>コンピュータウイルス等の有害なプログラムを当社または第三者に送信し、または流布する行為</li>
                    <li>法令に違反する行為</li>
                    <li>公序良俗を害する行為</li>
                    <li>本規約に違反し、または本サービスの利用の趣旨・目的に反する行為</li>
                    <li>その他、当社が合理的に不適切と判断する行為</li>
                </ul>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第７条（委託）</h2>
                <p class="p-about__txt">当社は、本サービスの提供に関して必要となる業務の全部または一部について、第三者に委託することができるものとします。</p>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第８条（利用停止）</h2>
                <ul class="p-about__listnum p-about__listnum--01">
                    <li>
                        当社は、利用者が次の各号のいずれかに該当すると判断した場合には、事前に通知することなく当該利用者による集英社IDの利用を一時的にまたは永続的に停止する措置を講じることができるものとします。これにより利用者に何らかの損害が生じたとしても、当社の故意または重大な過失に起因する場合を除き、当社は責任を負わないものとします。
                        <ul class="p-about__listnum p-about__listnum--02">
                            <li>本規約に違反する行為があった場合、または違反するおそれがあると当社が判断した場合</li>
                            <li>本サービスの利用に関して不正行為があった場合</li>
                            <li>登録情報に虚偽があった場合</li>
                            <li>過去に本サービスの一部または全部につき、一時的または永続的な利用停止を受けたことがある場合</li>
                            <li>通信販売における代金の支払い遅延、その他の個別サービスに関する債務不履行があった場合</li>
                            <li>一定回数以上のパスワードの入力ミスがある等、利用者のセキュリティを確保するために当社が必要と判断した場合</li>
                            <li>その他、当社が合理的に不適切と判断する行為を行った場合</li>
                        </ul>
                    </li>
                    <li>前項のほか、利用者が当社の定める一定の期間内に一定回数のログインを行わなかった場合、または一定期間内にメール、電話等の方法により利用者本人と連絡が取れなかった場合は、当社は、事前に通知することなく集英社IDの利用を一時的にまたは永続的に停止する措置を講じる場合があります。措置を講じたことにより利用者に何らかの損害が生じたとしても、当社の故意または重大な過失に起因する場合を除き、当社は責任を負わないものとします。</li>
                    <li>本条に基づき集英社IDの利用を永続的に停止する措置が講じられた場合、本規約5条3項が適用されるものとします。</li>
                </ul>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第９条（本サービスの変更、中断、終了等）</h2>
                <ul class="p-about__listnum p-about__listnum--01">
                    <li>当社は、本サービスの内容の全部もしくは一部を、利用者の承諾なく変更し、またはその提供を終了することができるものとします。</li>
                    <li>前項の場合、当社は、必要に応じて、適切な時期および適切な方法により、利用者に情報提供を行うものとします。</li>
                    <li>
                        当社は、以下の各号に定める場合、利用者に事前に通知することなく、本サービスの全部または一部の提供を一時的に中断することができるものとします。
                        <ul class="p-about__listnum p-about__listnum--02">
                            <li>本サービスを提供するための設備等の保守を定期的にまたは緊急に行う場合</li>
                            <li>アクセス過多等によって、システムに負荷が集中した場合</li>
                            <li>利用者、当社または第三者のセキュリティを確保する必要が生じた場合</li>
                            <li>災害、感染症の蔓延、戦争、暴動、労働争議等、当社の合理的支配を超えた偶発的事象が発生した場合</li>
                            <li>その他当社が合理的に必要と判断した場合</li>
                        </ul>
                    </li>
                    <li>本条に従った措置により利用者に損害が生じたとしても、当社の故意または重大な過失に起因する場合を除き、当社は責任を負わないものとします。</li>
                </ul>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第10条（免責事項）</h2>
                <ul class="p-about__listnum p-about__listnum--01">
                    <li>当社は、当社が本サービスを通じて提供するコンテンツ、その他の情報について、その完全性、正確性、最新性、有用性等を保証しないものとし、利用者は、そのことについて理解のうえ、本サービスを利用するものとします。</li>
                    <li>当社は、本サービスの利用に関連して、利用者に対する責任を負う場合には、当社の故意または重大な過失により利用者が被った損害を除き、利用者に現実に生じた通常かつ直接の範囲の損害に限り、これを賠償するものとします。</li>
                </ul>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第11条（知的財産権の帰属）</h2>
                <p class="p-about__txt">本サービスにおいて提供されるコンテンツ、その他の情報についての著作権、商標権、意匠権およびその他一切の知的財産権は、当社、または当該コンテンツ、その他の情報の提供元である第三者に帰属します。</p>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第12条（本規約の変更）</h2>
                <ul class="p-about__listnum p-about__listnum--01">
                    <li>当社は、本規約を変更するにあたり、あらかじめ合理的な事前周知期間を設け、本規約を変更する旨および変更後の本規約の内容、ならびにその効力発生時期を、当社のウェブサイト上に掲載することにより告知をします。</li>
                    <li>当社は、利用者の利益に適合する場合、もしくは利用者との契約の目的に反せずに、変更の必要性、変更後の内容の相当性、その他の事情に照らして合理性があると判断された場合には、利用者の承諾を得ることなく、本規約を任意にいつでも変更することができるものとします。</li>
                    <li>利用者は、本規約の変更の効力が生じた後に本サービスを利用した場合、変更後の本規約のすべての記載内容について同意したものとみなされます。変更後の本規約に同意できない場合、利用者は本サービスを利用することができません。</li>
                </ul>
            </section>
            <section class="p-about__sec">
                <h2 class="p-about__ttl c-ttl">第13条（準拠法、合意管轄）</h2>
                <p class="p-about__txt">本規約は日本法に基づき解釈されるものとし、本規約に関して生じた一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
            </section>
            <section class="p-about__sec">
                <p align="right">以上</p>
                <p align="right">（2021年８月25日制定）</p>
            </section>
            <section class="p-about__sec">
                <h2 id="services" class="p-about__ttl c-ttl">サービス一覧</h2>
                <p class="p-about__txt">集英社IDを利用してログインできる個別サービスは、下記のサービスです。</p>
                <ul class="p-about__list">
                    <li>【 HAPPY PLUS STORE 】
                        <ul class="p-about__list p-about__list--01">
                            <li>HAPPY PLUS STORE</li>
                            <li>eclat premiuｍ</li>
                            <li>LEEマルシェ</li>
                            <li>Marisol</li>
                            <li>mirabella</li>
                            <li>mirabella homme</li>
                            <li>zakka market</li>
                            <li>OUTLET</li>
                            <li>ジャンプキャラクターズストア&nbsp;HAPPY PLUS STORE店</li>
                            <li>WEEKLY SHONEN JUMP Digital</li>
                        </ul>
                    </li>
                    <li class="p-about__txt">【 HAPPY PLUS 】</li>
                    <li class="p-about__txt">【 ジャンプキャラクターズストア 】</li>
                    <li class="p-about__txt">【 ジャンプNAVI 】</li>
                    <li class="p-about__txt">【 HAPPY PLUS ACADEMIA 】</li>
                    <li class="p-about__txt">【 LEE 】</li>
                    <li class="p-about__txt">【 年末年始 集英社マンガ祭 】</li>
                    <li class="p-about__txt">【 UOMO 】</li>
                    <li>【 メンズマガジン 】
                        <ul class="p-about__list p-about__list--01">
                            <li>週プレNEWS</li>
                            <li>webスポルティーバ</li>
                            <li>MEN'S NON-NO WEB</li>
                        </ul>
                    </li>
                    <li class="p-about__txt">【 リマコミ＋ 】</li>
                    <li class="p-about__txt">【 集英社オンライン 】</li>
                </ul>
            </section>
        </div>
    </main>
</template>
<script setup lang="ts">
</script>


import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { PREFECTURE_LIST } from "@/commons/constants";
import { Field, Form } from "vee-validate";
import {
    checkAddressNotReqd,
    checkBirthdayNotReqd,
    checkBloodTypeNotReqd,
    checkBuildingNotReqd,
    checkFirstKanaNotReqd,
    checkFirstNameIsReqd,
    checkGenderIsReqd,
    checkHasChildNotReqd,
    checkIsMarriedNotReqd,
    checkLastKanaNotReqd,
    checkLastNameIsReqd,
    checkNicknameNotReqd,
    checkPrefectureNotReqd,
    checkTelIsReqd,
    checkZipCodeIsReqd, checkZipCodeNotReqd
} from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { sidClient } from "@/plugins/auth";
import moment from "moment";
import { Core as YubinBangoCore } from "yubinbango-core2";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { getServiceName, isSiteRegistered } from "@/commons/commonUtility";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";
import { useDateForm } from "@/commons/dateForm";
import { UserMensMagazine } from "@/commons/interfaces";
import {
    checkMensMagazineDrinkingNotReqd,
    checkMensMagazineDrivingLicenceNotReqd,
    checkMensMagazineIncomeNotReqd,
    checkMensMagazineIsSendMailMagazineNotReqd,
    checkMensMagazineIsSmokingNotReqd,
    checkMensMagazineJobIsReqd,
} from "@/commons/inputCheckSiteUniqueInfo";
import {
    DRINKING_LIST,
    DRIVING_LICENCE_LIST,
    FAVORITE_BRANDS_LIST,
    FAVORITE_COSMES_LIST,
    FAVORITE_HAIR_CARES_LIST,
    HOBBY_LIST,
    INCOME_LIST,
    INTEREST_SPORTS_LIST,
    IS_SMOKING_LIST,
    JOB_LIST,
    SPEND_HOLIDAYS_LIST,
} from "@/commons/linkage/sites/mensmagazine";
import NullableSelect from "@/components/NullableSelect.vue";

export default defineComponent({
    name: "MensMagazineProfile",
    components: {
        NullableSelect,
        Form,
        Field
    },
    props: {
        // 次画面の遷移先
        nextRoute: { type: String, required: true },
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const { setYear, setDate, date, years, months, dates, birthday } = useDateForm();
        const { linkageInfo, routeNames, serviceTermUrl, siteDefaultCallbackUrl } = LinkageItem.inject();

        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);
        const prefectureList = PREFECTURE_LIST;

        const userBase = userBaseItem.userData;
        const userMensMagazine = siteUniqItem.userData as UserMensMagazine;

        const formRef = ref<any>();
        // 初回サービス登録判定
        const firstServiceReg = route.name === `${routeNames.LOGIN_PROFILE}Child` && !isSiteRegistered(linkageInfo.linkageId, userBase);
        // MEN'S NON-NO用のボタン表示判定
        const isDispButton = linkageInfo.loginStateUnused && !firstServiceReg && route.name !== `${routeNames.SIGNUP_PROFILE}Child`;

        const serviceName = getServiceName(linkageInfo.linkageId);

        // 新規会員登録時の誕生日初期選択値
        if (!userBase.identityId) {
            setYear(NaN);
        }

        const moveConfirm = async () => {
            userBaseItem.storeStorage();
            siteUniqItem.storeStorage();
            await router.push({ name: props.nextRoute });
        };

        const changeMailMagazine = () => {
            if (!userMensMagazine.mailMagazine!.weeklyPlayboy) {
                userMensMagazine.mailMagazine!.weeklyPlayboy = false;
                userMensMagazine.hobby = [];
                userMensMagazine.hobbyOther = "";
            }
            if (!userMensMagazine.mailMagazine!.webSportiva) {
                userMensMagazine.mailMagazine!.webSportiva = false;
                userMensMagazine.interestSports = [];
                userMensMagazine.spendHolidays = [];
            }
            if (!userMensMagazine.mailMagazine!.mensNonNo) {
                userMensMagazine.mailMagazine!.mensNonNo = false;
                userMensMagazine.favoriteBrands = [];
                userMensMagazine.favoriteBrandOther = "";
                userMensMagazine.favoriteCosmes = [];
                userMensMagazine.favoriteHairCares = [];
            }
            formRef.value.validate("hobby");
            formRef.value.validate("hobbyOther");
            formRef.value.validate("interestSports");
            formRef.value.validate("spendHolidays");
            formRef.value.validate("favoriteBrands");
            formRef.value.validate("favoriteBrandOther");
            formRef.value.validate("favoriteCosmes");
            formRef.value.validate("favoriteHairCares");
        };

        // 以下4関数はMEN'S NON-NO用
        const moveChangePassword = async () => {
            await router.push({ name: routeNames.CHANGE_PASSWORD });
        };
        const moveChangeEmail = async () => {
            await router.push({ name: routeNames.CHANGE_EMAIL });
        };
        const moveWithdrowal = async () => {
            await router.push({ name: routeNames.WITHDRAWAL });
        };
        const logout = async () => {
            await sidClient.signOut();
            window.location.href = siteDefaultCallbackUrl as string;
        };

        const schema = Yup.object().shape({
            lastName: checkLastNameIsReqd,
            firstName: checkFirstNameIsReqd,
            lastKana: checkLastKanaNotReqd,
            firstKana: checkFirstKanaNotReqd,
            nickname: checkNicknameNotReqd,
            gender: checkGenderIsReqd,
            birthday: checkBirthdayNotReqd,
            zipCode: checkZipCodeNotReqd,
            prefecture: checkPrefectureNotReqd,
            address: checkAddressNotReqd,
            building: checkBuildingNotReqd,
            tel: checkTelIsReqd,
            isMarried: checkIsMarriedNotReqd,
            hasChild: checkHasChildNotReqd,
            bloodType: checkBloodTypeNotReqd,
            job: checkMensMagazineJobIsReqd,
            income: checkMensMagazineIncomeNotReqd,
            drivingLicence: checkMensMagazineDrivingLicenceNotReqd,
            isSmoking: checkMensMagazineIsSmokingNotReqd,
            drinking: checkMensMagazineDrinkingNotReqd,
            hobby: Yup.array().of(Yup.string()).when("mailMagazine.weeklyPlayboy", {
                is: true,
                then: Yup.array().of(Yup.string())
                    .min(1, "趣味を1つ以上、3つまで選択してください")
                    .max(3, "趣味を1つ以上、3つまで選択してください"),
                otherwise: Yup.array().of(Yup.string())
            }),
            hobbyOther: Yup.string().when("hobby", {
                is: (hobby: string[] | null) => hobby?.includes("その他"),
                then: Yup.string().nullable().required("趣味を入力してください"),
                otherwise: Yup.string().nullable()
            }),
            interestSports: Yup.array().of(Yup.string()).when("mailMagazine.webSportiva", {
                is: true,
                then: Yup.array().of(Yup.string())
                    .min(1, "興味のあるスポーツを1つ以上選択してください"),
            }),
            spendHolidays: Yup.array().of(Yup.string()).when("mailMagazine.webSportiva", {
                is: true,
                then: Yup.array().of(Yup.string())
                    .min(1, "休日の過ごし方を1つ以上選択してください"),
            }),
            favoriteBrands: Yup.array().of(Yup.string()).when("mailMagazine.mensNonNo", {
                is: true,
                then: Yup.array().of(Yup.string())
                    .min(1, "好きなファッションブランドを1つ以上、3つまで選択してください")
                    .max(3, "好きなファッションブランドを1つ以上、3つまで選択してください"),
            }),
            favoriteBrandOther: Yup.string().when("favoriteBrands", {
                is: (favoriteBrands: string[] | null) => favoriteBrands?.includes("その他"),
                then: Yup.string().nullable().required("好きなファッションブランドを入力してください"),
                otherwise: Yup.string().nullable()
            }),
            favoriteCosmes: Yup.array().of(Yup.string()).when("mailMagazine.mensNonNo", {
                is: true,
                then: Yup.array().of(Yup.string())
                    .min(1, "好きなスキンケア・コスメ（香水含む）のブランドを1つ以上、3つまで選択してください")
                    .max(3, "好きなスキンケア・コスメ（香水含む）のブランドを1つ以上、3つまで選択してください"),
            }),
            favoriteHairCares: Yup.array().of(Yup.string()).when("mailMagazine.mensNonNo", {
                is: true,
                then: Yup.array().of(Yup.string())
                    .min(1, "好きなヘアケア・頭皮ケアのブランドを1つ以上、3つまで選択してください")
                    .max(3, "好きなヘアケア・頭皮ケアのブランドを1つ以上、3つまで選択してください"),
            }),
            mailMagazine: Yup.object().shape({
                weeklyPlayboy: checkMensMagazineIsSendMailMagazineNotReqd,
                webSportiva: checkMensMagazineIsSendMailMagazineNotReqd,
                mensNonNo: checkMensMagazineIsSendMailMagazineNotReqd,
            })
        });

        /**
         * 住所自動入力
         */
        const addressAutoFill = () => {
            const zipCode = userBase.baseInfo.zipCode ? userBase.baseInfo.zipCode : undefined;
            new YubinBangoCore(zipCode, (addr: any) => {
                userBase.baseInfo.prefecture = addr.region; // 都道府県
                userBase.baseInfo.address = addr.locality; // 市区町村
                userBase.baseInfo.address += addr.street; // 町域
            });
        };

        const birthdayValidate = () => {
            formRef.value.validateField("birthday");
        };

        onMounted(async () => {
            // 誕生日をセット
            setDate(userBase.commonAttrInfo.birthday);

            // 基本属性情報の有無を判断
            if (userBase.identityId) {
                // ニックネーム
                userBase.commonAttrInfo.nickName = (userBase.commonAttrInfo.nickName || "")
                    .replaceAll("&lt;", "<")
                    .replaceAll("&gt;", ">");

                // if (userMensMagazine.identityId) {
                //     formRef.value.setFieldValue("isSendMailMagazine", userMensMagazine.isSendMailMagazine);
                // }

                const ret = await formRef.value.validate();
                // vee-validate 4.6.7 以降、slotのerrorsと連動していないため、setする
                formRef.value.setErrors(ret.errors);
            } else {
                // メールアドレスをセット
                try {
                    const userData = await sidClient.getUserData();
                    const email = userData.UserAttributes.filter((obj) => obj.Name === "email")[0].Value;
                    userBase.baseInfo.email = email;
                } catch (e) {
                    await router.push({
                        name: routeNames.SIGNUP_IDENTIFICATION,
                        query: { retUrl: router.currentRoute.value.name as string },
                        params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" },
                    });
                }
            }

            watchEffect(() => {
                const tmpDate = moment(`${date.year}-${date.month}-${date.day}`, "YYYY-M-D", true);
                userBase.commonAttrInfo.birthday = tmpDate.isValid() ? tmpDate.format("YYYY-MM-DD") : null;
            });

            // メールマガジンチェックが変更された場合、非表示項目をリセット
            watchEffect(() => {
                if (!userMensMagazine.mailMagazine?.weeklyPlayboy) {
                    userMensMagazine.hobby = [];
                    userMensMagazine.hobbyOther = "";
                }
                if (!userMensMagazine.mailMagazine?.webSportiva) {
                    userMensMagazine.interestSports = [];
                    userMensMagazine.spendHolidays = [];
                }
                if (!userMensMagazine.mailMagazine?.mensNonNo) {
                    userMensMagazine.favoriteBrands = [];
                    userMensMagazine.favoriteBrandOther = "";
                    userMensMagazine.favoriteCosmes = [];
                    userMensMagazine.favoriteHairCares = [];
                }
            });
        });

        return {
            userBase,
            userMensMagazine,
            date,
            birthday,
            years,
            months,
            dates,
            prefectureList,
            schema,
            addressAutoFill,
            moveConfirm,
            moveChangeEmail,
            moveChangePassword,
            moveWithdrowal,
            logout,
            birthdayValidate,
            firstServiceReg,
            isDispButton,
            formRef,
            serviceName,
            serviceTermUrl,
            JOB_LIST,
            INCOME_LIST,
            DRIVING_LICENCE_LIST,
            IS_SMOKING_LIST,
            DRINKING_LIST,
            HOBBY_LIST,
            INTEREST_SPORTS_LIST,
            SPEND_HOLIDAYS_LIST,
            FAVORITE_BRANDS_LIST,
            FAVORITE_COSMES_LIST,
            FAVORITE_HAIR_CARES_LIST,
            changeMailMagazine,
        };
    },
});

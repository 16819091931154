<template>
    <main class="l-page">
        <div class="p-help l-container">
            <h1 class="p-help__ttl--primary c-ttl--primary">よくあるご質問</h1>
            <section id="help_01" class="p-help__sec">
                <div class="p-help__secin">
                    <h2 class="p-help__ttl c-ttl">集英社IDとは？</h2>
                    <div class="p-help__answer">
                        <p class="p-help__txt">株式会社集英社が運営するサービスをより便利に、そして強固なセキュリティでより安全にご利用いただくためのアカウントIDです。<br>
                            集英社IDをご利用いただくことで、弊社が提供するサービスが同じIDとパスワードでスムーズにご利用いただくことができます。</p>
                        <p class="p-help__txt">現在（2024年12月16日時点）、以下のサイトやアプリが同じID（集英社ID）とパスワードでご利用いただけます。</p>
                        <ul class="p-help__list p-help__list--01">
                            <li>集英社の公式ファッション通販サイト「HAPPY PLUS STORE」</li>
                            <li>ファッション情報発信をしているサイト「HAPPY PLUS （ハピプラ）」</li>
                            <li>ジャンプキャラクターグッズの通販サイト「ジャンプキャラクターズストア」</li>
                            <li>ジャンプの情報を自分好みに、わかりやすくナビゲーションするアプリ「ジャンプNAVI」</li>
                            <li>動画コンテンツを提供するオンライン講座課金型サイト「HAPPY PLUS ACADEMIA」</li>
                            <li>LEEの情報発信をしているサイト「LEE」</li>
                            <li>豪華賞品の応募ができるキャンペーンサイト「年末年始 集英社マンガ祭」</li>
                            <li>文化系男子のためのウェブサイト「UOMO」</li>
                            <li>雑誌「週刊プレイボーイ」の公式総合エンタメサイト「週プレNEWS」</li>
                            <li>集英社の総合スポーツコラムメディア「webスポルティーバ」</li>
                            <li>男性ファッション誌「メンズノンノ」の公式サイト「MEN'S NON-NO WEB」</li>
                            <li>集英社少女・女性向けマンガポータルサイト「リマコミ＋」</li>
                            <li>ウェブニュースメディア「集英社オンライン」</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="help_02" class="p-help__sec">
                <div class="p-help__secin">
                    <h2 class="p-help__ttl c-ttl">新規登録・パスワード再設定時に「メールを送る」ボタンを押したがメールが届かない</h2>
                    <div class="p-help__answer">
                        <p class="p-help__txt">以下の原因が考えられますので、設定状況やメールフォルダをご確認ください。</p>
                        <ul class="p-help__list p-help__list--01" style="margin-bottom: 2.5rem;">
                            <li>
                                <span class="c-ttl--sub">ドメイン「@mail.id.shueisha.co.jp」からのメールが受信拒否設定になっている</span>
                                <ul class="p-help__list">
                                    <li>※ 受信設定方法はご利用の携帯電話やメールソフトにより異なります。</li>
                                </ul>
                            </li>
                            <li>
                                <span class="c-ttl--sub">迷惑メールフォルダに振り分けられている</span>
                            </li>
                            <li>
                                <span class="c-ttl--sub">１時間以内に何度も操作している</span>
                                <ul class="p-help__list">
                                    <li>※ 確認コードの有効期限は１時間となります。期限内に同じ操作をされましてもお手続きいただけません。<br>
                                        再度操作される場合は、１時間以上空けてから行ってください。</li>
                                </ul>
                            </li>
                        </ul>
                        <p class="p-help__txt">受信拒否設定をされた状態で、何度も確認コード送信の操作をされた場合、自動で送信を停止する機能が作動する場合がございます。<br>
                            受信設定されてもメールが届かない場合は調査をいたしますので、コンタクトセンターにお問い合わせください。</p>
                    </div>
                </div>
            </section>
            <section id="help_03" class="p-help__sec">
                <div class="p-help__secin">
                    <h2 class="p-help__ttl c-ttl">集英社IDでは、以下の条件に当てはまるメールアドレスはご登録いただけません。</h2>
                    <div class="p-help__answer">
                        <ul class="p-help__listnum p-help__listnum--01">
                            <li>
                                <span class="c-ttl--sub">世界共通となるインターネット通信規格RFC（Request for Comments）に準拠していない</span>
                                <ul class="p-help__list p-help__list--01" style="margin-top: 1.5rem;">
                                    <li>「.」を連続使用している場合</li>
                                    <li>「.」を最初と最後(@の直前)に使っている場合</li>
                                </ul>
                                <p class="p-help__txt">など</p>
                            </li>
                            <li>
                                <span class="c-ttl--sub">無数にメールアドレスを生成できるメールアドレス（gmailのエイリアスやyahooのセーフティアドレス等）</span>
                                <ul class="p-help__list p-help__list--01" style="margin: 1.5rem 0;">
                                    <li>gmailのエイリアスの例：aaa+bbb@gmail.com （＠の前に「+」が使われている）</li>
                                    <li>yahooのセーフティアドレスの例：aaa-bbb@yahoo.co.jp （＠の前に「-」が使われている）</li>
                                </ul>
                                <p class="p-help__txt">※gmailやyahooなどのフリーメール自体は引き続きご利用いただけます</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="help_04" class="p-help__sec">
                <div class="p-help__secin">
                    <h2 class="p-help__ttl c-ttl">メールに届いた確認コードとパスワードを入力しても次の画面に進みません</h2>
                    <div class="p-help__answer">
                        <p class="p-help__txt">以下の原因であることが考えられます。</p>
                        <ul class="p-help__list p-help__list--01" style="margin-bottom: 2.5rem;">
                            <li>
                                <span class="c-ttl--sub">確認コードが有効期限が切れている</span>
                                <ul class="p-help__list">
                                    <li>※ 期限はメール送信から1時間以内となります。期限が切れている場合は最初からお手続きください。</li>
                                </ul>
                            </li>
                            <li>
                                <span class="c-ttl--sub">入力された確認コードが間違っている</span>
                            </li>
                            <li>
                                <span class="c-ttl--sub">パスワードが英大文字・英小文字・数字を含む半角英数字8文字以上の条件を満たしていない</span>
                            </li>
                            <li>
                                <span class="c-ttl--sub">入力されたパスワードと確認用のパスワードが一致していない</span>
                            </li>
                        </ul>
                        <p class="p-help__txt">また、新規登録のお手続きで確認コードのメールに記載されているURLから直接アクセスした場合、ご利用のパソコンやメールソフトによっては次のページに進まないことがございます。<br>
                            この場合、記載されているURLアドレスを別のWebブラウザを開いて、アドレス欄に直接コピー＆ペーストし操作をお試しください。</p>
                        <p class="p-help__txt">上記をお試しいただきましても、お手続きいただけない場合は、誤った情報入力によるキャッシュ（履歴）が残っている可能性がありますので、キャッシュクリア（履歴削除）をお試しください。<br>
                            キャッシュクリアの方法は、ご利用のブラウザにより異なりますので、お調べいただきご操作をお願いいたします。</p>
                    </div>
                </div>
            </section>
            <section id="help_05" class="p-help__sec">
                <div class="p-help__secin">
                    <h2 class="p-help__ttl c-ttl">ログインできない</h2>
                    <div class="p-help__answer">
                        <p class="p-help__txt">メールアドレス、パスワードに誤りがないかご確認ください。</p>
                        <p class="p-help__txt">パスワードをお忘れの場合は、「パスワード再設定」からお手続きください。</p>
                        <p class="p-help__txt" style="overflow: hidden; text-overflow: ellipsis;">＜パスワード再設定＞<br>
                            <router-link style="text-decoration: underline; word-wrap:break-word;" :to="{ name: 'ResetPassword' }">https://id.shueisha.co.jp/reset_password</router-link>
                        </p>
                        <p class="p-help__txt">メールアドレス、パスワードに誤りがなくログインができない場合は、エラー内容等をコンタクトセンターへご連絡ください。</p>
                    </div>
                </div>
            </section>
            <section id="help_06" class="p-help__sec">
                <div class="p-help__secin">
                    <h2 class="p-help__ttl c-ttl">パスワードの設定がエラーになりできません</h2>
                    <div class="p-help__answer">
                        <p class="p-help__txt">「英字大文字、英字小文字、数字を含む8文字以上」の条件に満たしているかご確認ください。</p>
                        <p class="p-help__txt">条件に満たしたパスワードでもエラーになる場合は、エラー内容をコンタクトセンターへご連絡ください。</p>
                    </div>
                </div>
            </section>
            <section id="help_07" class="p-help__sec">
                <div class="p-help__secin">
                    <h2 class="p-help__ttl c-ttl">確認コードを再送してほしい</h2>
                    <div class="p-help__answer">
                        <p class="p-help__txt">最初のお手続きから１時間以上空けて再度お手続きください。</p>
                        <ul class="p-help__list">
                            <li>※ 新規会員登録時は確認コード発行から１時間は仮登録の状態となりますので、その間に同じメールアドレスで再度新規会員登録のお手続きいただきましても確認コードは送信されません。</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="help_08" class="p-help__sec">
                <div class="p-help__secin">
                    <h2 class="p-help__ttl c-ttl">「既に使用されているメールアドレス」と表示されて登録ができない</h2>
                    <div class="p-help__answer">
                        <p class="p-help__txt">以下の理由が考えれます。</p>
                        <p class="p-help__txt">◆すでに集英社IDに登録がある<br>
                            ※ご利用サービスの他に「HAPPY PLUS STORE」「HAPPY PLUS （ハピプラ）」「ジャンプキャラクターズストア」「ジャンプNAVI」「HAPPY PLUS ACADEMIA」「LEE」「年末年始 集英社マンガ祭」「UOMO」「週プレNEWS」「webスポルティーバ」「MEN'S NON-NO WEB」「リマコミ＋」「集英社オンライン」に登録がある</p>
                        <p class="p-help__txt" style="margin-bottom: 4.5rem;">◆新規登録が完了しておらず、仮登録の状態である</p>
                        <p class="p-help__txt">以下の手順にてご確認・ご登録ください。</p>
                        <ul class="p-help__listnum" style="margin-bottom: 2.5rem;">
                            <li>
                                <span class="c-ttl--sub">①集英社IDのサイトにログインする</span>
                                <ul class="p-help__list">
                                    <li><router-link style="text-decoration: underline; word-wrap:break-word;" :to="{ name: 'Login' }">https://id.shueisha.co.jp/login</router-link></li>
                                    <li style="margin-bottom: 0px;">※パスワードをお忘れの方は、パスワード再設定からお手続きください。</li>
                                    <li><router-link style="text-decoration: underline; word-wrap:break-word;" :to="{ name: 'ResetPassword' }">https://id.shueisha.co.jp/reset_password</router-link></li>
                                </ul>
                            </li>
                            <li>
                                <span class="c-ttl--sub">②マイページの「おすすめのサービス」から登録したいサービスを確認する</span>
                            </li>
                            <li>
                                <span class="c-ttl--sub">③「サービスを連携する」を押す</span>
                            </li>
                            <li>
                                <span class="c-ttl--sub">④該当サービスのサイトにページが移動したら、同じアカウント情報でログインし登録を完了させる</span>
                            </li>
                        </ul>
                        <p class="p-help__txt">①でログインができない、パスワード再設定のメールが届かない場合は、仮登録の状態であることが考えられます。<br>
                            最後に操作してから1時間以上空けて、ご利用サービスより再度、新規登録をお試しください。<br>
                            ※仮登録とは…お送りしたメールに記載の確認(認証)コードの入力または、会員情報登録がお済みでない状態</p>
                    </div>
                </div>
            </section>
            <section id="help_09" class="p-help__sec">
                <div class="p-help__secin">
                    <h2 class="p-help__ttl c-ttl">集英社IDを退会したい</h2>
                    <div class="p-help__answer">
                        <p class="p-help__txt">集英社IDを退会するためには、ご利用中の全てのサービスの連携解除（退会手続き）が必要です。</p>
                        <p class="p-help__txt">◆ご利用中サービスの連携解除方法</p>
                        <ul class="p-help__listnum p-help__listnum--01" style="margin-bottom: 2.5rem;">
                            <li>
                                <span class="c-ttl--sub">集英社IDのサイトにログインする</span>
                                <ul class="p-help__list">
                                    <li><router-link style="text-decoration: underline; word-wrap:break-word;" :to="{ name: 'Login' }">https://id.shueisha.co.jp/login</router-link></li>
                                </ul>
                            </li>
                            <li>
                                <span class="c-ttl--sub">マイページの「ご利用中のサービス」を確認する</span>
                            </li>
                            <li>
                                <span class="c-ttl--sub">ご利用中のサービスの「サービス連携を解除する」からサービスサイトへアクセスし、退会する</span>
                                <ul class="p-help__list">
                                    <li>※ご利用中のサービスがない場合は、「集英社ID退会方法」へお進みください。</li>
                                </ul>
                            </li>
                            <li>
                                <span class="c-ttl--sub">全てのサービスの退会手続き完了後に、集英社IDの退会手続きを行う</span>
                            </li>
                        </ul>
                        <p class="p-help__txt">◆集英社ID退会方法</p>
                        <ul class="p-help__listnum p-help__listnum--01" style="margin-bottom: 2.5rem;">
                            <li>
                                <span class="c-ttl--sub">集英社IDのサイトにログインする</span>
                                <ul class="p-help__list">
                                    <li><router-link style="text-decoration: underline; word-wrap:break-word;" :to="{ name: 'Login' }">https://id.shueisha.co.jp/login</router-link></li>
                                </ul>
                            </li>
                            <li>
                                <span class="c-ttl--sub">スマートフォン：右上にある「・・・」から「退会する」を押す</span><br />
                                <span class="c-ttl--sub">パソコン&emsp;&emsp;&emsp;：左メニュー最下部の「退会する」を押す</span>
                            </li>
                            <li>
                                <span class="c-ttl--sub">「退会する」を押す</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>
<script setup lang="ts">
</script>
